const bifForm = document.getElementById('backgroundInformationForm-form');
const contactForm = document.getElementById('contact-form');

if ( bifForm !== null ) {
    bifForm.addEventListener('onSproutFormsSubmit', function(event) {
        const submitButton = document.getElementsByTagName('button')[0];

        submitButton.classList.add('spinner');
    }, false);

}
if ( contactForm !== null ) {
    contactForm.addEventListener('onSproutFormsSubmit', function(event) {
        const submitButton = document.getElementsByTagName('button')[0];

        submitButton.classList.add('spinner');
    }, false);

}
