export function toggleClass(element, className) {

    if ( ifContainsClass(element, className) ) {
        removeClass(element, className);

    } else {
        addClass(element, className);

    }

}

export function ifContainsClass(element, className) {
    return element.classList.contains(className);
}

export function addClass(element, className) {
    element.classList.add(className);
}

export function removeClass(element, className) {
    element.classList.remove(className);
}

export function setCookie(cname, cvalue, exdays) {
    let d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

// get specific cookie
export function getCookie(name) {
    let cookie = {};
    document.cookie.split(';').forEach(function(el) {
        let [k,v] = el.split('=');
        cookie[k.trim()] = v;
    });
    return cookie[name];
}
