import * as helpers from './helpers.js';

const mobileMenuToggle = document.getElementById('menuBtn');

mobileMenuToggle.addEventListener("click", function(event) {
    event.preventDefault();

    const mobileMenu = document.getElementById('menu');

    helpers.toggleClass(mobileMenu, 'open');
});
