import * as helpers from './helpers.js';

const bodyElement = document.body;
const textSizer = document.getElementById('textSizer').children;

for (let i = 0; textSizer.length > i; i++) {
    textSizer[i].addEventListener('click', function(event) {
        event.preventDefault();

        let sizerButtonValue = textSizer[i].getAttribute('id');
        bodyElement.setAttribute('id', sizerButtonValue);
        helpers.setCookie('baseTextSize', sizerButtonValue, 30);

    });

}

// set text size from cookie
(function () {
    bodyElement.setAttribute('id', helpers.getCookie('baseTextSize'));
})();


