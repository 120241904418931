import * as helpers from './helpers.js';

const accordionItem = document.querySelectorAll('.accordion-item');

accordionItem.forEach(function(slide) {
    slide.addEventListener('click', function() {

        helpers.toggleClass(slide, 'is-open');
    });
});
